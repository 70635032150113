import React, { useRef, useState, useEffect } from "react";
import "./charityList.scss";
import CharityCard from "./../../components/common/CharityCard/index";
import { debounce, toAbsoluteUrl } from "../../utils";
import { useDispatch } from "react-redux";
import { newCharityList, renderCharityList } from "../../actions/charity";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import InfiniteScroll from "react-infinite-scroll-component";
import Modal from "react-modal";
import Cancle from "../../assets/svg/cancle";
import Search from "../../assets/svg/search";
import { Link, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { Loading } from "../../components/common/LoderData";
import { WORDPRESS_BASE } from "../../constants/api";

const filterOptions = {
  Contribution: [
    {
      id: 0,
      selected: false,
      name: "Below $50,000",
      minContribuionAmount: 0,
      maxContribuionAmount: 50000,
    },
    {
      id: 1,
      selected: false,
      name: "$50,001 - $250,000",
      minContribuionAmount: 50001,
      maxContribuionAmount: 250000,
    },
    {
      id: 2,
      selected: false,
      name: "$250,001 - $1,000,000",
      minContribuionAmount: 250001,
      maxContribuionAmount: 1000000,
    },
    {
      id: 3,
      selected: false,
      name: "$1,000,001 - $10,000,000",
      minContribuionAmount: 1000001,
      maxContribuionAmount: 10000000,
    },

    // {
    //   id: 4,
    //   selected: false,
    //   name: "$1,000,001 - $5,000,000",
    //   minContribuionAmount: 1000001,
    //   maxContribuionAmount: 5000000,
    // },
    // {
    //   id: 5,
    //   selected: false,
    //   name: "$5,000,001 - $25,000,000",
    //   minContribuionAmount: 5000001,
    //   maxContribuionAmount: 25000000,
    // },
    // {
    //   id: 6,
    //   selected: false,
    //   name: "$25,000,001 - $50,000,000",
    //   minContribuionAmount: 25000001,
    //   maxContribuionAmount: 50000000,
    // },
    // {
    //   id: 7,
    //   selected: false,
    //   name: "$50,000,001 - $100,000,000",
    //   minContribuionAmount: 50000001,
    //   maxContribuionAmount: 100000000,
    // },
    {
      id: 4,
      selected: false,
      name: "$10,000,001 +",
      minContribuionAmount: 10000001,
      maxContribuionAmount: 100000000000000,
    },
  ],
  TypeofCharity: [
    { id: 0, selected: false, name: "International" },
    { id: 1, selected: false, name: "National" },
    { id: 2, selected: false, name: "Local" },
    // { id: 3, selected: false, name: "Other" },
  ],
};

const bread = [
  {
    name: "Explore Nonprofits",
    link: "/charity",
    isActive: true,
  },
];

export default function CharityList() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    setIsScrolled(position > 50);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();

  const parsedQuery = queryString.parse(window.location.search, {
    arrayFormat: "comma",
  });
  const setInitialContribution = () => {
    return filterOptions?.Contribution?.filter((item) =>
      parsedQuery?.maxAmt?.includes(item.maxContribuionAmount.toString())
    ).map((item) => item.name);
  };

  const setInitialCharityType = () => {
    return filterOptions?.TypeofCharity.filter(
      (item) => item.id + 1 === Number(parsedQuery.type)
    )[0]?.name;
  };

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedContribution, setSelectedContribution] = useState(
    setInitialContribution()
  );
  // setInitialContribution()
  const [selectedCharityType, setSelectedCharityType] = useState(
    setInitialCharityType()
  );
  const [contributionCnt, setContributionCnt] = useState(
    parsedQuery?.maxAmt ? 1 : 0
  );
  const [charityTypeCnt, setCharityTypeCnt] = useState(
    parsedQuery?.type ? 1 : 0
  );
  const [queryParams, setQueryParams] = useState({
    maxAmt: parsedQuery.maxAmt?.length
      ? Array.isArray(parsedQuery.maxAmt)
        ? parsedQuery.maxAmt
        : [Number(parsedQuery.maxAmt)]
      : [],
    minAmt: parsedQuery.minAmt?.length
      ? Array.isArray(parsedQuery.minAmt)
        ? parsedQuery.minAmt
        : [Number(parsedQuery.minAmt)]
      : [],
    search: parsedQuery?.search || "",
    type: Number(parsedQuery.type),
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [args, setArgs] = useState({
    limit: 12,
  });
  const searchRef = useRef();

  const setParams = (payload) => {
    const params = {};
    for (const key in payload) {
      if (payload[key]) {
        params[key] = payload[key];
      }
    }

    let queryUrl = queryString.stringify(params, { arrayFormat: "comma" });
    navigate(`?${queryUrl}`);
  };
  const onChangeFun = (e) => {
    if (e.length >= 3 || e.length === 0)
      setQueryParams((prev) => {
        return {
          ...prev,
          search: e,
        };
      });
    setParams({ ...queryParams, search: e });
  };

  const optimizedFn = debounce(onChangeFun);

  const randerCharityListHandler = (queryParams) => {
    if (queryParams.search?.length < 3 && queryParams.search?.length > 0)
      return;
    let payload = {
      page: 1,
      limit: 12,
      search: queryParams.search?.replace("-", ""),
      filter: [],
    };

    if (
      queryParams.search?.replace("-", "").length === 9 &&
      /^\d+$/.test(queryParams.search?.replace("-", ""))
    ) {
      payload = {
        ...payload,
        taxIdSearch: queryParams.search?.replace("-", ""),
      };
      delete payload.search;
    }

    if (queryParams.search?.length) {
      searchRef.current.value = queryParams.search;
      payload = { ...payload, isNavigator: true };
    }

    if (queryParams.maxAmt.length) {
      payload.filter = setContPayload();
    }
    if (queryParams.type) {
      payload.typeOfCharity = queryParams.type;
    }

    if (id?.id) {
      dispatch(
        newCharityList(
          { ...payload, notificationId: id?.id, isFlag: true },
          true
        )
      ).then((res) => {
        if (res?.data?.list?.length === 0) {
          setHasMore(false);
          setData([]);
        } else {
          setHasMore(false);
          setData(res?.data?.list);
        }
      });
    } else {
      dispatch(renderCharityList(payload, false)).then((res) => {
        if (res?.data?.charityFilter?.length === 0) {
          setHasMore(false);
          setData([]);
        } else {
          setHasMore(true);
          setData(res?.data?.charityFilter);
        }
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.search]);

  useEffect(() => {
    randerCharityListHandler(queryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.search, id]);

  const setContPayload = () => {
    const amountArr = [];
    if (Array.isArray(queryParams.maxAmt)) {
      for (let i = 0; i < queryParams.maxAmt.length; i++) {
        amountArr.push({
          maxContribuionAmount: Number(queryParams.maxAmt[i]),
          minContribuionAmount: Number(queryParams.minAmt[i]),
        });
      }
    } else {
      amountArr.push({
        maxContribuionAmount: Number(queryParams.maxAmt),
        minContribuionAmount: Number(queryParams.minAmt),
      });
    }

    return amountArr;
  };

  const fetchData = () => {
    let payload = {
      ...args,
      // maxContribuionAmount: queryParams.maxAmt,
      // minContribuionAmount: queryParams.minAmt,
      typeOfCharity: queryParams.type,
      search: queryParams.search?.replace("-", ""),
      page: page + 1,
    };

    payload.filter = setContPayload();
    if (!queryParams.maxAmt) {
      delete payload.maxContribuionAmount;
    }
    if (!queryParams.minAmt) {
      delete payload.minContribuionAmount;
    }
    if (!queryParams.type) {
      delete payload.typeOfCharity;
    }

    if (queryParams.search?.length) {
      payload = { ...payload, isNavigator: true };
    }
    dispatch(renderCharityList(payload)).then((res) => {
      if (res?.data?.charityFilter?.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setData([...data, ...res?.data?.charityFilter]);
      }
    });

    setPage(page + 1);
  };

  const onSubmitModal = (data) => {
    setParams(queryParams);
    randerCharityListHandler(queryParams);
  };

  const openModal = () => {
    setModalIsOpen((prev) => !prev);
  };

  const modalSubmitHandler = () => {
    setModalIsOpen((prev) => !prev);
    onSubmitModal({ ...args, page: 1 });
  };

  const modalResetHandler = () => {
    setSelectedContribution([]);
    setSelectedCharityType("");
    setCharityTypeCnt(0);
    setContributionCnt(0);

    const payload = {
      ...queryParams,
      maxAmt: [],
      minAmt: [],
      type: 0,
    };
    setQueryParams(payload);
    setParams(payload);

    setArgs((prev) => ({
      ...prev,
      limit: 12,
      typeOfCharity: "",
    }));
    setPage(1);
    setModalIsOpen((prev) => !prev);
    randerCharityListHandler(payload);
  };

  const onContributionValueChange = (e) => {
    const index = selectedContribution.indexOf(e.target.value);
    if (index === -1) {
      setSelectedContribution((prev) => [...prev, e.target.value]);
      setQueryParams({
        ...queryParams,
        maxAmt: [
          ...queryParams.maxAmt,
          Number(e.target.attributes.maxcontribuionamount.value),
        ],
        minAmt: [
          ...queryParams.minAmt,
          Number(e.target.attributes.mincontribuionamount.value),
        ],
      });
      setContributionCnt(1);
    } else {
      const temp = [...selectedContribution];
      if (temp.length) {
        temp.splice(index, 1);
      }
      setSelectedContribution(temp);

      const qp = { ...queryParams };
      if (qp.maxAmt.length) {
        qp.maxAmt = qp.maxAmt?.splice(index, 1);
        qp.minAmt = qp.minAmt?.splice(index, 1);
      }
      setContributionCnt(queryParams.maxAmt.length ? 1 : 0);
      setQueryParams(queryParams);
    }
  };
  const onCharityTypeValueChange = (e) => {
    setSelectedCharityType(e.target.value);
    setCharityTypeCnt(e.target.checked === true ? 1 : 0);
    setQueryParams({
      ...queryParams,
      type: Number(e.target.attributes.id.value) + 1,
    });
  };

  const isChecked = (name) => {
    const temp = selectedContribution?.includes(name);
    return temp;
  };

  return (
    <>
      <section className="charityList">
        <div className="container">
          <div className="breadcrumbs">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="charitylistWrap">
          <div className={`charity-header ${isScrolled ? "sticky" : ""}`}>
            <div className="container">
              <div className="non-profit-header">
                <div className="non-profit-title">
                  <h3>Featured Nonprofits</h3>
                  <div className="learnMoreWrap">
                    <a
                      className="learnTextLink"
                      href={WORDPRESS_BASE + "/nonprofits/"}
                    >
                      Learn More
                    </a>
                  </div>
                </div>

                <div className="giving_partner_button">
                  <a
                    href="/charity-registration"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* Register as Giving Partner */}
                    Register as a Nonprofit
                  </a>
                </div>
              </div>
              <div className="charity-header-wrap">
                <div className="charityList-search">
                  <div className="charity-search-formGrp">
                    <span>
                      <Search />
                    </span>
                    <input
                      type="text"
                      placeholder="Search Nonprofits by Name or Tax ID/EIN"
                      ref={searchRef}
                      onChange={(e) => optimizedFn(e.target.value)}
                    />
                    {queryParams.search && (
                      <span
                        className="cancle-cause"
                        onClick={() => {
                          setQueryParams({ ...queryParams, search: "" });
                          setParams({ ...queryParams, search: "" });
                          searchRef.current.value = "";
                        }}
                      >
                        <Cancle />
                      </span>
                    )}
                  </div>
                </div>
                <div className="charityModal">
                  <strong>Filter </strong>
                  <button className="filterButton" onClick={openModal}>
                    <img
                      src={toAbsoluteUrl("/images/Filter.png")}
                      alt="filter"
                    />
                    <span className="filterbadge">
                      {contributionCnt + charityTypeCnt}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {data?.length ? (
              <InfiniteScroll
                dataLength={data?.length}
                next={() => fetchData(false)}
                hasMore={hasMore}
                loader={<Loading />}
              >
                <div className="charity-wrap">
                  {data?.map((charities, ind) => (
                    <CharityCard
                      charities={charities}
                      key={charities?._id}
                      setData={setData}
                      page={page}
                      limit={args?.limit}
                      search={queryParams.search?.replace("-", "")}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <p className="notFound">No Nonprofit List Found</p>
            )}
          </div>
        </div>
      </section>

      {/* charity filter modal */}
      <Modal
        className={`custom-modal filterModal`}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setModalIsOpen((prev) => !prev);
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-header">
          <h3>
            Filters <span className="note">(check all that apply)</span>
          </h3>
          <button
            onClick={() => {
              setModalIsOpen((prev) => !prev);
            }}
          >
            <Cancle />
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-contribution">
            <div className="contribution-title">
              <input
                id="contribution"
                className="check"
                type="radio"
                defaultChecked={true}
                onChange={() => ""}
              />
              <span htmlFor="contribution">Revenue</span>
            </div>

            <div className="contribution-checkbox">
              {filterOptions.Contribution.map((contribution, ind) => (
                <div className="contribution-input" key={contribution.id}>
                  <input
                    type="checkbox"
                    key={contribution.id}
                    value={contribution?.name}
                    checked={isChecked(contribution.name)}
                    onChange={onContributionValueChange}
                    className="check"
                    mincontribuionamount={contribution?.minContribuionAmount}
                    maxcontribuionamount={contribution?.maxContribuionAmount}
                    id={contribution.id}
                  />
                  <span htmlFor={contribution.id}>{contribution.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-charity">
            <div className="charity-title">
              <input
                className="check"
                type="radio"
                defaultChecked={true}
                onChange={() => ""}
              />
              <span>Type of Nonprofit</span>
            </div>

            <div className="charity-checkbox">
              {filterOptions.TypeofCharity.map((charityType, ind) => (
                <div className="charity-input" key={charityType.id}>
                  <input
                    type="radio"
                    key={charityType.id}
                    value={charityType?.name}
                    checked={charityType.name === selectedCharityType}
                    onChange={onCharityTypeValueChange}
                    className="check"
                    id={charityType.id}
                  />
                  <span htmlFor={charityType.id}>{charityType.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="submit"
            onClick={modalSubmitHandler}
            disabled={contributionCnt || charityTypeCnt ? false : true}
          >
            Submit
          </button>
          <button
            type="button"
            className="submit"
            onClick={modalResetHandler}
            disabled={false}
          >
            Reset
          </button>
        </div>
      </Modal>
    </>
  );
}
