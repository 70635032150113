import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { givingPartnerDetailsAction } from "../../actions/givingPartner";
import AboutContent from "../../components/common/aboutContent";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import "./givingPartner.scss";
import { WORDPRESS_BASE } from "../../constants/api";

const GivingPartner = () => {
  const bread = [
    {
      name: "Giving Partner",
      link: "/giving_partner",
      isActive: true,
    },
  ];

  const givingPartner = useSelector(
    (state) => state?.givingPartners?.givingPartners
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(givingPartnerDetailsAction(true)).then((res) => {});
  }, [dispatch]);

  return (
    <>
      <section className="giving_partner">
        <div className="container">
          <div className="breadcrumb_giving_partner">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <div className="giving-partner-header">
            <div className="giving-partner-title">
              <h3 className="pageTitle">Giving Partner</h3>
              <div className="learnMoreWrap">
                <a
                  className="learnTextLink"
                  href={WORDPRESS_BASE + "/partners/"}
                >
                  Learn More
                </a>
              </div>
            </div>

            <div className="giving_partner_button">
              <a
                href={`${process.env.REACT_APP_CHARITY_PUBLIC_URL}signup`}
                target="_blank"
                rel="noreferrer"
              >
                {/* Register as Giving Partner */}
                Register as a Giving Partner
              </a>
            </div>
          </div>
          <section className="giving_partner_content">
            <AboutContent
              className="giving_content"
              contentImage="/images/logo.svg"
              contentDescription={givingPartner?.content}
              image={givingPartner?.image}
              from="giving"
            />
          </section>
          <div className="giving_partner_button giving_button">
            <a
              href={`${process.env.REACT_APP_CHARITY_PUBLIC_URL}signup`}
              target="_blank"
              rel="noreferrer"
            >
              {/* Register as Giving Partner */}
              Register as a Giving Partner
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default GivingPartner;
